import { Switch } from '@thanx/uikit/switch'
import { getCouponPools } from 'actions/couponPools'
import { locationsApi } from 'api/locations'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  getInstoreRedemptionType,
  getUniqueRedemptionProviders,
  mapProviderPoolsToCouponCodeDisplay,
} from 'scenes/RedeemManager/helpers'
import ProviderInstoreRedemptionDisplay from 'scenes/RedeemManager/shared/ProviderInstoreRedemptionDisplay'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import Card from './Card'
import InstoreRedemptionCard from './InstoreRedemptionCard'

const t = buildTranslate('redeem_manager.detail.cards.instore_redemption')

type Props = {
  template: RedeemTemplate
}

function MultipleInstoreRedemptionCard({ template }: Props) {
  const locationSpecificPosEnabled = useFlag('provider-redemption', false)
  const merchant = useCurrentMerchant()
  const dispatch = useDispatch()
  const { id: templateId } = template
  const multiplePosSystems =
    locationSpecificPosEnabled && merchant?.has_multi_pos

  const { data: locationsData, isSuccess: loadLocationsSuccess } =
    locationsApi.useGetLocationsQuery({ full_visibility: true })
  const merchantLocations = locationsData?.locations || []
  const redemptionProviders = getUniqueRedemptionProviders(
    merchantLocations,
    merchant?.redemption_provider
  )
  const isAvailable =
    template.redemption_venue === 'instore' ||
    template.redemption_venue === 'all'

  const couponPools = useSelector(state =>
    selectCouponPoolsByTemplateId(state, template.id)
  )

  useEffect(() => {
    if (!couponPools?.length) {
      dispatch(getCouponPools(templateId))
    }
  }, [couponPools, templateId, dispatch])

  return (
    <Switch
      condition={multiplePosSystems && loadLocationsSuccess}
      fallback={<InstoreRedemptionCard template={template} />}
    >
      <Switch
        condition={isAvailable}
        fallback={<p className="grey-70">{t('not_available')}</p>}
      >
        <Card title={t('title')} info={t('info')} isActive={isAvailable}>
          {redemptionProviders.map(provider => (
            <ProviderInstoreRedemptionDisplay
              key={provider.value}
              redemptionProvider={provider}
              redemptionType={getInstoreRedemptionType(
                template,
                couponPools?.filter(
                  pool => pool.redemption_provider?.value === provider.value
                )
              )}
              template={template}
              couponCodeDisplay={mapProviderPoolsToCouponCodeDisplay(
                couponPools,
                provider
              )}
            />
          ))}
        </Card>
      </Switch>
    </Switch>
  )
}

export default MultipleInstoreRedemptionCard
